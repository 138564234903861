import React from "react";
import { Route, Switch } from "react-router";
import AdminInterface from "../admin/AdminInterface";
import Login from "../login/Login";
import usePersistedState from "./usePersistedState";

export default function Routes() {
  const [loggedIn, setLoggedIn] = usePersistedState("loggedIn", false);
  if (!loggedIn) {
    return (
      <div className="app">
        <div className="wrapper__main">
          <Switch>
            <Route
              render={(props) => (
                <Login loggedIn={loggedIn} setLoggedIn={setLoggedIn} />
              )}></Route>
          </Switch>
        </div>
      </div>
    );
  }
  return (
    <>
      <Switch>
        <Route
          exact
          path="/"
          render={(props) => (
            <AdminInterface {...props}></AdminInterface>
          )}></Route>
      </Switch>
    </>
  );
}
