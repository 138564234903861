const programDetails = [
    { value: "1000", text: 'Bachelor in Business Administration' },
    { value: "1001", text: 'Master of Business Administration' },
    { value: "1002", text: 'CIM UK' },
    { value: "1003", text: 'CMI UK' },
    { value: "1004", text: 'Doctor of Business Administration' },
    { value: "1005", text: 'Executive MSc in Digital Marketing' },
    { value: "1006", text: 'Executive MSc in Entrepreneurship and Innovation' },
    { value: "1007", text: 'Executive MSc in Strategic Marketing' },
    { value: "1008", text: 'Executive MSc in Finance' },
    { value: "1009", text: 'Executive MSc in Strategic Management' },
]
export default programDetails