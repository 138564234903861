import {
  Button,
  Col,
  Divider,
  Dropdown,
  Input,
  PaginationProps,
  Row,
  Table,
  TablePaginationConfig,
  Typography,
} from "antd";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useQueryString } from "use-route-as-state";
import {
  TPayment,
  TQueryParams,
  TStudent,
  YQueryParams,
  YPayment,
  TPaymentArr,
  YPaymentArr,
  defaultSortFilterValues,
} from "./admin.types";
import { ColumnsType } from "antd/es/table";
import connection from "../common/connection";
import { format } from "date-fns";
import paymentTypes from "./paymentTypes";
import programDetails from "./programDetails";
import GetColumnSearchProps from "./GetColumnSearchProps";
import { FilterValue, SortOrder } from "antd/lib/table/interface";
import ButtonGroup from "antd/lib/button/button-group";
import ExportInterface from "./ExportInterface";
const makeColumns: (
  defaultValues: defaultSortFilterValues
) => ColumnsType<TPayment> = (defaultValues: defaultSortFilterValues) => [
  {
    title: "Ref. No.",
    dataIndex: "reference_number",
    fixed: "left",
  },
  {
    title: "Date Time",
    dataIndex: "signed_date_time",
    render: (signed_date_time) =>
      `${format(new Date(signed_date_time), `yy-LL-dd hh:mmaaa`)}`,
    sorter: true,
    sortDirections: ["ascend", "descend", "ascend"],
    defaultSortOrder: defaultValues.defaultSortOrder as SortOrder,
  },
  {
    title: "Student ID",
    dataIndex: "student_id",
    key: "student_id",
    ...GetColumnSearchProps("student_id"),
    defaultFilteredValue: defaultValues.student_id.defaultFilteredValue as
      | FilterValue
      | null
      | undefined,
  },
  {
    title: "Student Name",
    dataIndex: "student",
    key: "student_name",
    render: (student: TStudent) =>
      `${student.bill_to_forename} ${student.bill_to_surname}`,
  },
  {
    title: "Student Email",
    dataIndex: "student",
    key: "student_email",
    ellipsis: true,

    render: (student: TStudent) => `${student.bill_to_email}`,
  },
  {
    title: "Payment Type",
    dataIndex: "payment_type_details",
    render: (payment_type_details) => `${payment_type_details.ref_name}`,
    filters: paymentTypes,
    defaultFilteredValue: defaultValues.payment_type.defaultFilteredValue as
      | FilterValue
      | null
      | undefined,
  },
  {
    title: "Program",
    dataIndex: "program_details",
    render: (program_details) => `${program_details.ref_name}`,
    width: "20%",
    ellipsis: true,
    filters: programDetails,
    defaultFilteredValue: defaultValues.program.defaultFilteredValue as
      | FilterValue
      | null
      | undefined,
  },
  {
    title: "Amount (LKR)",
    dataIndex: "amount",
  },
];

interface AdminInterfaceProps {}

const AdminInterface: React.FC<AdminInterfaceProps> = (props) => {
  const [params, updateParams] = useQueryString();
  const [data, setData] = useState(undefined as unknown as TPaymentArr);
  const [count, setCount] = useState(0);
  const [load, setLoad] = useState(true);
  const [err, setErr] = useState(false);
  useEffect(() => {
    if (err) return setLoad(false);
  }, [err]);

  useLayoutEffect(() => {
    updateParams(YQueryParams.validateSync(params) as any);
    console.log("test");
  }, []);

  useEffect(() => {
    setErr(false);
    try {
      const paramsWT = YQueryParams.validateSync(params);
      (async () => {
        setLoad(true);
        const res = await connection.get("api/transactions", {
          params: paramsWT,
        });
        if (res.status === 200) {
          setLoad(false);
          setData(YPaymentArr.validateSync(res.data.DATA.data));
          setCount(res.data.DATA.total_count);
        }
      })();
    } catch (e) {
      setErr(true);
      console.log(e);
    }
  }, [params]);
  return (
    <>
      <Row justify="center" style={{ textAlign: "center" }}>
        <Col>
          <Typography.Title level={3}>Payment Admin Portal</Typography.Title>
          <Typography.Text>
            Please use the icons next to each table heading to sort and filter
            through items. Click on export buttons to download the data in Excel
            format. You can download data accordingly to filters and sort
            applied. You can choose to download the current page or all the
            pages associated with the applied filters or sort.
          </Typography.Text>
        </Col>
      </Row>
      <Divider></Divider>
      <ExportInterface params={params}></ExportInterface>
      <Table
        loading={load}
        bordered
        dataSource={data}
        columns={makeColumns({
          defaultSortOrder: params.sort,
          payment_type: { defaultFilteredValue: params.payment_type },
          program: { defaultFilteredValue: params.program },
          student_id: { defaultFilteredValue: params.student_id },
        })}
        onChange={(pagination: any, filters: any, sorter: any) => {
          console.log(pagination, filters, sorter);
          let { payment_type_details, program_details, student_id } = filters;
          student_id =
            student_id && student_id.length > 0 ? student_id[0] : undefined;

          payment_type_details = payment_type_details?.toString();
          program_details = program_details?.toString();
          // const paramsWT = YQueryParams.validateSync(params);
          const { current, pageSize } = pagination;
          const { order } = sorter;
          const start: number =
            ((current as number) - 1) * (pageSize as number);
          const limit: number = pageSize as number;
          // const output = {
          //   ...params,
          //   start: start.toString(),
          //   limit: limit.toString(),
          // };
          const output = {
            ...params,
            sort: order,
            start: start.toString(),
            limit: limit.toString(),
            student_id,
            payment_type: payment_type_details,
            program: program_details,
          };
          console.log(output);
          updateParams(output);
        }}
        pagination={{
          total: count,
          pageSize: parseInt(params.limit),
          showSizeChanger: true,
          position: ["topCenter", "bottomCenter"],
        }}
        scroll={{ x: 1000 }}></Table>
    </>
  );
};

export default AdminInterface;
