import { Row, Typography } from "antd";
import React from "react";

function FooterComp() {
  return (
    <div className="footer">
      {/* <Row justify="center">
              <Typography.Text style={{ fontSize: 12 }}>
                All Rights Reserved. Cambridge College
              </Typography.Text>
            </Row> */}
      <Row justify="center">
        <Typography.Text style={{ fontSize: 12 }}>Powered By</Typography.Text>
      </Row>
      <Row justify="center">
        <a href="https://seamlessc.com">
          <img
            src="https://seamlessc.com/wp-content/uploads/2021/02/SeamlessC-300x45.png"
            width="120 "></img>
        </a>{" "}
      </Row>
    </div>
  );
}
export default React.memo(FooterComp);
