import React, { useState } from "react";
import {
  Alert,
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Typography,
} from "antd";

interface LoginProps {
  loggedIn: boolean;
  setLoggedIn: any;
}

const Login: React.FC<LoginProps> = ({ loggedIn, setLoggedIn }) => {
  const [falseCred, setFalseCred] = useState(false);
  const userName = "ccbmadmin";
  const password = "ccbmpay";
  return (
    <div className="wrapper__section">
      <Row justify="center" style={{ textAlign: "center" }}>
        <Col>
          <Typography.Title level={3}>Payment Admin Portal</Typography.Title>
          <Typography.Text>
            {" "}
            Please enter your credentials to login
          </Typography.Text>
        </Col>
      </Row>
      <br></br>
      <Row justify="center">
        <Alert
          message={"Do not login through unknown computers!"}
          type="error"
        />
      </Row>
      <Divider></Divider>
      <Row justify="center">
        <Col xs={22} sm={19} md={16} lg={13} xl={10} xxl={8}>
          <Form
            onFinish={(values: any) => {
              if (
                userName === values.username &&
                password === values.password
              ) {
                setLoggedIn(true);
              } else {
                setFalseCred(true);
              }
            }}>
            <Form.Item
              label="Username"
              name="username"
              rules={[
                {
                  required: true,
                  message: "Username is required!",
                },
              ]}>
              <Input />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}>
              <Input.Password />
            </Form.Item>
            <Row justify="center">
              <Button type="primary" htmlType="submit">
                Login
              </Button>
            </Row>
            {falseCred ? (
              <Row justify="center" style={{ paddingTop: "16px" }}>
                <Alert
                  message={"Credentials are wrong! Try again."}
                  type="error"
                  showIcon
                />
              </Row>
            ) : null}
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default Login;
