import axios from "axios";

const connection = axios.create({
    baseURL:
        // process.env.NODE_ENV === "development"
        // ? "http://localhost:9930"
        // :
        "https://backend.ccbm.lk/",
});
export default connection;
