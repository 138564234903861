import React from "react";
import logo from "./logo.svg";
import "./App.less";
import { Table } from "antd";
import HeaderComp from "./features/common/HeaderComp";
import FooterComp from "./features/common/FooterComp";
import SectionWrapper from "./features/common/SectionWrapper";
import Routes from "./features/common/Routes";

function App() {
  return (
    <>
      <HeaderComp></HeaderComp>
      <div className="App">
        <div className="wrapper__main">
          {/* <SectionWrapper> */}
          <Routes></Routes>
          {/* </SectionWrapper> */}
        </div>
        <FooterComp></FooterComp>
      </div>
    </>
  );
}

export default App;
