import { object, string, number, InferType, ref, mixed, boolean, date, array } from 'yup'
export const YQueryParams = object({
    sort: string().required().default('descend'),
    start: number().required().min(0).default(0),
    limit: number().required().min(0).default(100),
    student_id: string().notRequired(),
    program: string().notRequired(),
    payment_type: string().notRequired(),
    start_date: string().notRequired(),
    end_date: string().notRequired()
}).noUnknown().required()
export const YStudent = object({
    bill_to_forename: string().required(),
    bill_to_surname: string().required(),
    bill_to_phone: string().required(),
    bill_to_email: string().required(),
}).required().noUnknown()
export type TQueryParams = InferType<typeof YQueryParams>;
export const YPayment = object({
    reference_number: string().required(),
    student: YStudent,
    student_id: string().required(),
    amount: number().required(),
    payment_type: number().required(),
    program: number().required(),
    program_details: object({
        ref_name: string().required(),

    }).noUnknown().required(),
    payment_type_details: object({
        ref_name: string().required(),

    }).noUnknown().required(),
    signed_date_time: string().required(),
}).required().noUnknown()

export const IPaymentType = object({
    student_id: string().required("Student id is required"),
    program: string().required("Program is required"),
    payment_type: string().required("Payment type is required"),
    amount: number().required('Amount is required').positive('Amount should be positive'),
    signed_date_time: string().required('Date is required'),
    reference_number: string().required(),
    program_details: object({ ref_name: string().required() }).required(),
    payment_type_details: object({ ref_name: string().required() }).required(),
    student: object({
        bill_to_email: string().email().required("Student email is required"),
        bill_to_forename: string().required("Student first name is required"),
        bill_to_phone: string().min(9).required("Student phone is required"),
        bill_to_surname: string().required("Student surname is required"),
    }).required()
}).required().noUnknown();

export const YPaymentArr = array(YPayment)
export type TPayment = InferType<typeof YPayment>
export type TPaymentArr = InferType<typeof YPaymentArr>
export type TStudent = InferType<typeof YStudent>

export type TPaymentType = InferType<typeof IPaymentType>
export type defaultSortFilterValues = {
    defaultSortOrder: string | undefined,
    student_id: {
        defaultFilteredValue: string | undefined,
    },
    program: {
        defaultFilteredValue: string | undefined,
    },
    payment_type: {
        defaultFilteredValue: string | undefined,
    },

}