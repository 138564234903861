import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Col, Modal, Row } from "antd";
import { format } from "date-fns";
import React, { useState } from "react";
import * as XLSX from "xlsx";
import connection from "../common/connection";
import { TPaymentType } from "./admin.types";
const { confirm } = Modal;
interface ExportInterfaceProps {
  params: any;
}

const ExportInterface: React.FC<ExportInterfaceProps> = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const constructCurrentData = (data: TPaymentType[]) => {
    return data?.map((item: TPaymentType) => {
      const {
        reference_number,
        signed_date_time: date_time,
        student_id,
        student,
        amount,
        payment_type_details,
        program_details,
      } = item;
      return {
        reference_number,
        date_time,
        student_id,
        student_name: `${student.bill_to_forename} ${student.bill_to_surname}`,
        student_email: student.bill_to_email,
        student_phone: student.bill_to_phone,
        payment_type: payment_type_details.ref_name,
        program: program_details.ref_name,
        amount,

        //  item.referen null
      };
    });
  };
  function showCurrentExport(params: any,current:boolean) {
    confirm({
      title: current?"Do you want to download only the current page?":"Do you want to download all the pages?",
      icon: <ExclamationCircleOutlined />,
      content:
        "Click the download button to download the content in Excel (.xlsx) format",
      onOk: async () => {
        const res = await connection.get("api/transactions", {
          params: params,
        });
        const data = res.status === 200 ? res.data.DATA.data : undefined;
        const writeData = constructCurrentData(data);
        const header = [
          "reference_number",
          "date_time",
          "student_id",
          "student_name",
          "student_email",
          "student_phone",
          "payment_type",
          "program",
          "amount",
        ];
        let wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(writeData, { header: header });
        XLSX.utils.book_append_sheet(wb, ws, "Test");
        console.log(data);
        XLSX.writeFile(
          wb,
          `ccbm-payment-data-${format(new Date(), `yy-LL-dd hh:mmaaa`)}.xlsx`
        );
      },
      onCancel() {},
    });
  }
  return (
    <>
      <Row justify="space-around">
        <Col>
          <Button onClick={() => showCurrentExport(props.params,true)}>
            Export Current Page
          </Button>
        </Col>
        <Col>
          <Button
            onClick={() =>
              showCurrentExport({
                ...props.params,
                start: "0",
                limit: "10000000000000000",
              },false)
            }>
            Export All Pages
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default ExportInterface;
